import React from "react";

const Egitim = ({ data }) => {
  if (!data) {
    return <p>Loading data...</p>;
  }

  const color = "linear-gradient(to right, #eea941 40%, #f686ad 100%)";
  const customTitleStyle = {
    background: color,
  };

  return (
    <div id="features" className="text-left"> {/* Ensure text aligns left */}
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2 style={{ marginTop: "130px", marginBottom: "-40px", textAlign: "center" }}>
            ROTA-WEEK CHALLENGE
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div>
              <div>
                <div
                  className="section-title h3 custom-title"
                  style={customTitleStyle}
                >
                  {data.title}
                </div>

                {/* Text and Image Container */}
                <div style={{ display: "flex", alignItems: "start", marginBottom: "20px" }}>
                  
                  {/* Text aligned to the left */}
                  <div style={{ flex: 1, textAlign: "left" }}>
                    <p><strong>{data.content1}</strong></p>
                    <p>{data.content2}</p>
                    <p>{data.content3}</p>
                    <p>{data.content4}</p>
                  </div>

                  {/* Image on the right */}
                  <img
                    src="/img/rotaweek/okuryazarlik.png"
                    alt="Hakkımızda Görseli"
                    style={{
                      width: "350px",
                      height: "auto",
                      marginLeft: "20px", // Space between text and image
                      display: "block"
                    }}
                  />
                </div>

                {/* Full-Width Text Below */}
                <p><strong>{data.content5}</strong></p>
                <p>{data.content6}</p>
                <p>{data.content7}</p>
                <p><strong>{data.content8}</strong></p>
                <p>{data.content9}</p>
                <p>{data.content10}</p>
                <p><strong>{data.content11}</strong></p>
                <p>{data.content12}</p>
                <p>{data.content13}</p>
                <p>{data.content14}</p>
                <p>{data.content15}</p>
                <p><strong>{data.content16}</strong></p>
                <p>{data.content17}</p>
                <p><strong>{data.content18}</strong></p>
                <p>{data.content19}</p>
                <p><strong>{data.content20}</strong></p>
                <p>{data.content21}</p>
                <p><strong>{data.content22}</strong></p>
                <p>{data.content23}</p>
                <p><strong>{data.content24}</strong></p>
                <p>{data.content25}</p>
                <p>{data.content26}</p>
                <p><strong>{data.content27}</strong></p>
                <p>{data.content28}</p>
                <p>{data.content29}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Egitim;
