import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  // ✅ Separate states for different submenus
  const [isAboutHovered, setIsAboutHovered] = useState(false);
  const [isRotaWeekHovered, setIsRotaWeekHovered] = useState(false);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img
              src={"img/navigation/LogoLockup.png"}
              alt="Logo"
              style={{
                maxWidth: "200px",
                maxHeight: "140px",
                marginTop: "-15px",
                marginLeft: "-40px",
              }}
            />
          </Link>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* 🔹 HAKKIMIZDA Dropdown */}
            <li
              onMouseEnter={() => setIsAboutHovered(true)}
              onMouseLeave={() => setIsAboutHovered(false)}
            >
              <Link to="/rotaract" className="page-scroll">
                Hakkımızda
              </Link>
              {isAboutHovered && (
                <ul className="submenu">
                  <li>
                    <Link to="/rotaract">Rotaract Nedir?</Link>
                  </li>
                  <li>
                    <Link to="/rotary">Rotary Nedir?</Link>
                  </li>
                  <li>
                    <Link to="/tando">Ankara Tandoğan Rotaract Kulübü</Link>
                  </li>
                  <li>
                    <Link to="/team">
                      Yönetim Kurulumuz & Komite Başkanlarımız
                    </Link>
                  </li>
                  <li>
                    <Link to="/focus">Odak Noktalarımız</Link>
                  </li>
                  <li>
                    <Link to="/quaternary">Dörtlü Özdenetim</Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Other Navigation Items */}
            <li>
              <Link to="/projects" className="page-scroll">
                PROJELERİMİZ
              </Link>
            </li>
            <li>
              <Link to="/gallery" className="page-scroll">
                MEDYA
              </Link>
            </li>
            <li>
              <Link to="/press" className="page-scroll">
                BASINDA BİZ
              </Link>
            </li>
            <li>
              <Link to="/contact" className="page-scroll">
                İLETİŞİM
              </Link>
            </li>

            {/* 🔹 ROTA WEEK CHALLENGE Dropdown - FIXED */}
            <li
              onMouseEnter={() => setIsRotaWeekHovered(true)}
              onMouseLeave={() => setIsRotaWeekHovered(false)}
            >
              <Link to="" className="page-scroll">
                ROTA-WEEK CHALLENGE
              </Link>
              {isRotaWeekHovered && (
                <ul className="submenu">
                  <li>
                    <Link to="/baris-ve-anlasmazlik">
                      Barış ve Anlaşmazlıkların Çözümü
                    </Link>
                  </li>
                  <li>
                    <Link to="/hastalik">Hastalıkların Önlenmesi ve Tedavisi</Link>
                  </li>
                  <li>
                    <Link to="/cevre1">Çevreyi Koruma ve Destekleme 1</Link>
                  </li>
                  <li>
                    <Link to="/cevre2">Çevreyi Koruma ve Destekleme 2</Link>
                  </li>
                  <li>
                    <Link to="/anne">Anne ve Çocuk Sağlığı</Link>
                  </li>
                  <li>
                    <Link to="/egitim">Temel Eğitim ve Okuryazarlık</Link>
                  </li>
                  <li>
                    <Link to="/su">Temiz Su ve Hijyen</Link>
                  </li>
                  <li>
                    <Link to="/ekonomi">Ekonomik ve Toplumsal Kalkınma</Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
